.App {
    background-color: hsla(0, 0%, 0%, .75);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 80%;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    letter-spacing: 1.2px;
    line-height: 1.4;
}

.header-text {
    color: white;
    font-size: 3rem;
}

.colored-text {
    color: #F29F05;
    font-weight: 600;
}
.logos-container {
    display: flex;
    justify-content: center;

}

.buttons-container {
    display: flex;
    justify-content: center;
}

.cards-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.902);

}

#hidden {
    visibility: hidden;
}

#visbile {
    visibility: visible;
}