.button {
    background-color: #F29F05;
    padding: 18px;
    font-size: 1.5rem;
}

.button-div {
    position: relative ;
    padding: 0;
    margin: 20px;
    overflow: hidden;
}

@keyframes animate1 {
 0% { 
    transform: translateX(-100%);
 }
 100% {
    transform: translateX(100%);
 }
}

@keyframes animate2 {
    0% { 
       transform: translateY(-100%);
    }
    100% {
       transform: translateY(100%);
    }
   }

@keyframes animate3 {
    0% { 
       transform: translateX(100%);
    }
    100% {
       transform: translateX(-100%);
    }
   }

@keyframes animate4 {
    0% { 
       transform: translateY(100%);
    }
    100% {
       transform: translateY(-100%);
    }
   }

.button-div:hover {
    cursor: pointer;
   }

.button-div:hover .span1 {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right,rgba(4, 157, 191, 0), rgba(4, 157, 191, 1)) ;
    animation: animate1 2s linear infinite;

}

.button-div:hover .span2 {
    position: absolute;
    top:0;
    right :0;
    width: 4px;
    height: 100%;
    background: linear-gradient(to bottom,rgba(4, 157, 191, 0), rgba(4, 157, 191, 1)) ;
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
}

.button-div:hover .span3 {
    animation: animate3 2s linear infinite;
    position: absolute;
    bottom :0;
    right :0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to left,rgba(4, 157, 191, 0), rgba(4, 157, 191, 1)) ;
}

.button-div:hover .span4 {
    position: absolute;
    bottom :0;
    left :0;
    width: 4px;
    height: 100%;
    background: linear-gradient(to top,rgba(4, 157, 191, 0), rgba(4, 157, 191, 1)) ;
    animation: animate4 2s linear infinite;
    animation-delay: 1s;
}